<!-- 新增工单 -->
<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="工单分类" prop="taskKind">
            <el-select
              style="width: 100%"
              v-model="ruleForm.taskKind"
              placeholder="请选择工单分类"
            >
              <el-option label="移动" :value="1"> 移动 </el-option>
              <el-option label="联通" :value="2"> 联通 </el-option>
              <el-option label="电信" :value="3"> 电信 </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="预约时间" prop="date">
            <el-date-picker
              style="width: 100%"
              v-model="ruleForm.date"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="预期办理活动" prop="hd">
            <el-input
              type="text"
              v-model="ruleForm.hd"
              placeholder="请输入预期办理活动"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="礼品要求" prop="lp">
            <el-input
              type="text"
              v-model="ruleForm.lp"
              placeholder="请输入礼品要求"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="11">
          <el-form-item label="客户昵称" prop="kh_name">
            <el-input
              type="text"
              v-model="ruleForm.kh_name"
              placeholder="请输入客户昵称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="工单地址" prop="address">
            <el-input
              type="text"
              v-model="ruleForm.address"
              placeholder="请输入工单地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="联系账号" prop="contact">
            <el-input
              type="text"
              v-model="ruleForm.contact"
              placeholder="请输入联系账号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="是否本人身份证办理号码" prop="oneself">
            <el-radio-group v-model="ruleForm.oneself">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="证件是否齐全" prop="is_zj">
            <el-radio-group v-model="ruleForm.is_zj">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="是否达量限速客户" prop="is_xs">
            <el-radio-group v-model="ruleForm.is_xs">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
              <el-radio :label="2">未知</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="是否需要宽带" prop="is_kd">
            <el-radio-group v-model="ruleForm.is_kd">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
              <el-radio :label="2">未知</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="宽带安装地址">
            <el-input
              type="text"
              v-model="ruleForm.kd_address"
              placeholder="请输入宽带安装地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        taskKind: "",
        date: "",
        hd: "",
        lp: "",
        kh_name: "",
        address: "",
        contact: "",
        oneself: "",
        is_zj: "",
        is_xs: "",
        is_kd: "",
        kd_address: "",
        user_id: JSON.parse(window.localStorage.getItem("user")).id,
      },
      rules: {
        taskKind: [
          { required: true, message: "请选择工单分类", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
        hd: [
          { required: true, message: "请输入预期办理活动", trigger: "blur" },
        ],
        lp: [{ required: true, message: "请输入礼品要求", trigger: "blur" }],
        kh_name: [
          { required: true, message: "请输入客户昵称", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入工单地址", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入联系账号", trigger: "blur" },
        ],
        oneself: [
          {
            required: true,
            message: "请选择是否本人身份证办理号码",
            trigger: "blur",
          },
        ],
        is_zj: [
          { required: true, message: "请选择证件是否齐全", trigger: "blur" },
        ],
        is_xs: [
          {
            required: true,
            message: "请选择是否达量限速客户",
            trigger: "blur",
          },
        ],
        is_kd: [
          { required: true, message: "请选择是否需要宽带", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let response = await this.$request.post("/addTask", this.ruleForm);
          if (response.data.success) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.resetForm();
          } else {
            this.$message({
              message: response.data.msg,
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "请输入必选项",
            type: "error",
          });
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>
<style lang='less' scoped>
</style>